type ImageSize = "320" | "375" | "425" | "768" | "1024";

interface ImageVariants {
    mobile?: Partial<Record<ImageSize, string>>;
    tablet?: Partial<Record<ImageSize, string>>;
    desktop?: Partial<Record<ImageSize, string>>;
}

interface ImagePaths {
    milosc: ImageVariants;
    zona_maz: ImageVariants;
    razem_lepiej: ImageVariants;
}

export const imagePaths: ImagePaths = {
    milosc :{
        mobile: {
            "320": require('../assets/images/mobile/320/milosc_320.webp'),
            "375": require('../assets/images/mobile/375/milosc_375.webp'),
            "425": require('../assets/images/mobile/425/milosc_425.webp'),
        },
        tablet: {
            "768": require('../assets/images/tablet/milosc_768.webp'),
        },
        desktop: {
            "1024": require('../assets/images/desktop/milosc_1024.webp'),
        }
    },
    zona_maz :{
        mobile: {
            "320": require('../assets/images/mobile/320/zona_maz_320.webp'),
            "375": require('../assets/images/mobile/375/zona_maz_375.webp'),
            "425": require('../assets/images/mobile/425/zona_maz_425.webp'),
        },
        tablet: {
            "768": require('../assets/images/tablet/zona_maz_768.webp'),
        },
        desktop: {
            "1024": require('../assets/images/desktop/zona_maz_1024.webp'),
        }
    },
    razem_lepiej :{
        mobile: {
            "320": require('../assets/images/mobile/320/razem_lepiej_320.webp'),
            "375": require('../assets/images/mobile/375/razem_lepiej_375.webp'),
            "425": require('../assets/images/mobile/425/razem_lepiej_425.webp'),
        },
        tablet: {
            "768": require('../assets/images/tablet/razem_lepiej_768.webp'),
        },
        desktop: {
            "1024": require('../assets/images/desktop/razem_lepiej_1024.webp'),
        }
    }    
};