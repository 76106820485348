import styled, { css } from 'styled-components';
import logo from '../../assets/images/logo.svg';
import facebookIcon from '../../assets/images/facebook_logo.svg';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 425px) {
        flex-direction: column;
    }

`

export const Logo = styled.div`
    width: 174px;
    background-image: url('${logo}'); 
    background-size: contain;
    background-repeat: no-repeat;
    height: 178px;

    @media (max-width: 425px) {
        width: 90px;
        height: 92px;
        margin-bottom: 10px;
    }
`
const commonStyle = css`
    color: #fff;
    font-size: 40px;
    text-align: right;
`

export const NavigationTexts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    border-radius: 20px;
    background: #7c6d6d;

    & > div{
        display:flex;
        align-items:center;
        margin-top:10px;

        @media (max-width: 768px) {
            flex-direction:column;
        }

        @media (max-width: 425px) {
            flex-direction:row;
        }
    }

    

    @media (max-width: 768px) {
        flex-direction:column;
        max-width:50%;
    }

    @media (max-width: 425px) {
        max-width:100%;
    }


`

export const WelcomeMessage = styled.h1`
    ${commonStyle}

    @media (max-width: 425px) {
        font-size: 23px;
    }

    @media (max-width: 375px) {
        font-size: 19px;
    }
`

export const PhoneNumber = styled.a`
    text-decoration: none;
    ${commonStyle}
    margin-right:10px;
    font-size: 35px;

    @media (max-width: 768px) {
        font-size: 22px;
    }

    @media (max-width: 375px) {
        font-size: 15px;
    }
`

export const SocialLinks = styled.a`
    display:inline-block;
    background-image: url('${facebookIcon}');
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height:35px;
`