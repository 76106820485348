import { Logo, NavigationTexts, PhoneNumber, SocialLinks, WelcomeMessage, Wrapper } from "./style/navigation"

export const Navigation = () => {
    return (
        <Wrapper>
            <Logo/>
            <NavigationTexts>
                <WelcomeMessage>Witaj na stronie neonowechwile.pl</WelcomeMessage>
                <div>
                <PhoneNumber href="tel:+48789038645">+48 789 038 645</PhoneNumber>
                <PhoneNumber href="tel:+48509371198">+48 509 371 198</PhoneNumber>
                <SocialLinks href="https://www.facebook.com/profile.php?id=100095559064626" target="_blank" aria-label="facebook icon"/>
                </div>
            </NavigationTexts>
        </Wrapper>
    )
}