import '../reset.css';
import { Navigation } from './navigation';
import { NeonList } from './neon-list';
import styled from 'styled-components';

const AppWrapper = styled.div`
  padding: 20px 30px;
  background: #dbd8d6;

  @media (max-width: 768px) {
      padding: 20px 5px 0 5px;
  }
`

function App() {
  return (
    <AppWrapper>
      <Navigation/>
      <NeonList/>
    </AppWrapper>
  );
}

export default App;
