import styled from "styled-components";
import { PopularWidths } from "../../helper/currentPageWidth";

const {TABLET_MAX, MOBILE_L_MAX, MOBILE_M_MAX, MOBILE_S_MAX, MOBILE_M_MIN, MOBILE_L_MIN, TABLET_MIN} = PopularWidths;

export const NeonListWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
`

export const NeonItemWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding:20px;
    background:#7c6d6d;
    margin-bottom:5px; 
    max-width:1200px;
    align-self:center;
    border-radius: 20px;
    margin-top: 35px;

    @media (max-width: ${TABLET_MAX}px) {
        flex-direction:column;
        margin-top: 15px;
    }
`
export const NeonItemImage = styled.img`
    width: 50%;
    height: auto;
    border-radius: 20px;

    
    @media (max-width: ${MOBILE_S_MAX}px) {
        width: 270px;
        height: 175px;
    }

    @media (min-width: ${MOBILE_M_MIN}px) and (max-width: ${MOBILE_M_MAX}px) {
        width: 325px;
        height: 211px;
    }

    @media (min-width: ${MOBILE_L_MIN}px) and (max-width: ${MOBILE_L_MAX}px) {
        width: 375px;
        height: 243px;
    }

    @media (min-width: ${TABLET_MIN}px) and (max-width: ${TABLET_MAX}px) {
        width: 718px;
        height: 466px;
    }
`

export const NeonItemText = styled.span`
    padding-left:20px;
    color: #fff;
    width: 50%;

    p{
        border-radius: 5px;
        padding:5px;
        color: #fff;
        
        &:nth-last-child(1),
        &:nth-last-child(2){
            text-align: center;
        }
    }

    @media (max-width: ${TABLET_MAX}px) {
        width:100%;
    }
    
`

export const DescTitle = styled.p`
    font-size: 40px;
    text-align:center;

    @media (max-width: ${TABLET_MAX}px) {
        margin:10px 0;
    }
`