
import { neonsData } from '../data/neonsData';
import { NeonItemImage, NeonItemText, NeonItemWrapper, NeonListWrapper, DescTitle } from './style/neon-list';



export const NeonList = () =>{
    
    return(
        <NeonListWrapper>
            {neonsData.map( ({title, image, alt, questions, answers, price, dimensions}, index) => {
                return(
                    <NeonItemWrapper key={title + index}>
                        <NeonItemImage key={title + index + "100"} src={image} alt={alt} />
                        <NeonItemText key={title + index + "200"}>
                            <DescTitle key={title + index + "300"}>{title}</DescTitle>
                            {questions.map((item, questionIndex) => <p key={`${title}-${index}-question-${questionIndex}`}>{item}</p>)}
                            {answers.map((item, answerIndex) => <p key={`${title}-${index}-answer-${answerIndex}`}>{item}</p>)}
                            <p>{price}</p>
                            <p>{dimensions}</p>
                        </NeonItemText>
                    </NeonItemWrapper>
                )
            })}
        </NeonListWrapper>
    )
}