import { getCurrentPageWidth } from '../helper/currentPageWidth';
import { imagePaths } from './imagePaths';

const selectCorrectImage = () => {
    const {isMobileS,isMobileM,isMobileL,isTablet} = getCurrentPageWidth();
    const PART_OF_PATH = isMobileS ? "mobile" : (isMobileM ? "mobile" : (isMobileL ? "mobile" : (isTablet ? "tablet" : "desktop")));
    const FILE_NAME = isMobileS ? "320" : (isMobileM ? "375" : (isMobileL ? "425" : (isTablet ? "768" : "1024")));
    const miloscSrc = imagePaths.milosc?.[PART_OF_PATH]?.[FILE_NAME];
    const razemLepiejSrc = imagePaths.razem_lepiej?.[PART_OF_PATH]?.[FILE_NAME];
    const zonaMazSrc = imagePaths.zona_maz?.[PART_OF_PATH]?.[FILE_NAME];

    return {
        miloscSrc,
        razemLepiejSrc,
        zonaMazSrc
    }
}

export const neonsData = [
    {
        image: selectCorrectImage().miloscSrc,
        alt: "love neon image",
        title: 'Miłość',
        questions: [
            'Czy chcesz, aby Twoje wesele było pełne miłości i magii? Mamy dla Ciebie idealne rozwiązanie! Nasz uroczy neonowy napis "Miłość" doda wyjątkowego uroku i romantycznego klimatu podczas Twojej uroczystości.', 
        ],
        answers: [
            'Romantyczny design: Nasz neon "Miłość" to piękny i wyrazisty akcent, który przyciągnie uwagę i rozpali uczucia wszystkich gości.',
            'Podkreśl magię chwili: Delikatne światło neonu dodaje czaru i wyjątkowości każdemu ważnemu momencie podczas Twojego wesela.',
            'Wspaniałe zdjęcia: Neon "Miłość" stworzy idealne tło dla romantycznych fotografii, które zachwycą Was i Waszych bliskich na długie lata.'
        ],
        price: '**Cena: NA 150ZŁ za całe wesele!**',
        dimensions: 'Wymiary: 83.5cm x 48.2cm'
    },
    {
        image: selectCorrectImage().zonaMazSrc,
        alt: "better together neon image",
        title: 'Żona Mąż',
        questions: [
            'Czy szukasz czegoś niezwykłego, co podkreśli miłość i związek pomiędzy Wami dwojgiem? Nasz uroczy neonowy napis "Żona Mąż" to idealny sposób na wyrażenie Waszej miłości w wyjątkowy sposób podczas Waszego święta miłości.'
        ],
        answers: [
            'Symbol Twojego związku: Nasz neon "Żona Mąż" jest pięknym symbolem waszego małżeństwa, który przypomni Wam o Waszej miłości przez wiele lat.',
            'Wyjątkowy design: Delikatne światło neonu podkreśli Wasz wyjątkowy związek, tworząc romantyczną atmosferę, która oczaruje Waszych gości.',
            'Niesamowite zdjęcia: Neon "Żona Mąż" stworzy magiczne tło dla fotografii, które uchwycą najpiękniejsze chwile Waszego święta miłości.'            
        ],
        price: '**Cena: 150ZŁ za wynajem na całe wesele!**',
        dimensions: 'Wymiary: 83.5cm x 23.6cm'
    },
    {
        image: selectCorrectImage().razemLepiejSrc,
        alt: "wife husband neon image",
        title: 'Razem Lepiej',
        questions: [
            'Marzysz o niezwykłym dodatku, który wprowadzi magię na Twoje wesele? Mamy coś specjalnego dla Ciebie! Nasz uroczy neonowy napis "Razem Lepiej" to doskonały sposób na dodanie wyjątkowego blasku i atmosfery podczas Twojej uroczystości.'
        ],
        answers: [
            'Unikalny design: Nasz neonowy napis "Razem Lepiej" jest oryginalnym akcentem, który przyciągnie uwagę gości i sprawi, że Twój ślub będzie wyjątkowy.',
            'Atmosfera romantyzmu: Delikatne światło neonu dodaje romantycznego nastroju, który urozmaici każdy moment Waszego wesela.',
            'Doskonałe tło do fotografii: Neonowy napis stworzy niesamowite tło dla zdjęć, które pozostaną piękną pamiątką na całe życie.'
        ],
        price: '**Cena: 150ZŁ za wynajem za całe wesele!**',
        dimensions: 'Wymiary: 83.6cm x 47.5cm'
    }
]