export enum PopularWidths {
    MOBILE_S_MAX = 320,
    MOBILE_M_MIN = MOBILE_S_MAX + 1,
    MOBILE_M_MAX = 375,
    MOBILE_L_MIN = MOBILE_M_MAX + 1,
    MOBILE_L_MAX = 425,
    TABLET_MIN = MOBILE_L_MAX + 1,
    TABLET_MAX = 768,
    DESKTOP_S_MIN = TABLET_MAX + 1,
    DESKTOP_S_MAX = 1024,
    DESKTOP_M_MIN = DESKTOP_S_MAX + 1,
    DESKTOP_M_MAX = 1440,
    DESKTOP_L_MIN = DESKTOP_M_MAX + 1
}

export const getCurrentPageWidth = () => {
    const currentPageWidth = Number(String(window.innerWidth).replace('px',''));
    let isMobileS = false;
    let isMobileM = false;
    let isMobileL = false;
    let isTablet = false;
    let isDesktop = false;

    switch (true) {
        case (currentPageWidth <= PopularWidths.MOBILE_S_MAX):
            isMobileS = true;
            break;
        case (currentPageWidth >= PopularWidths.MOBILE_M_MIN && currentPageWidth <= PopularWidths.MOBILE_M_MAX):
            isMobileM = true;
            break;
        case (currentPageWidth >= PopularWidths.MOBILE_L_MIN && currentPageWidth <= PopularWidths.MOBILE_L_MAX):
            isMobileL = true;
            break;
        case (currentPageWidth >= PopularWidths.TABLET_MIN && currentPageWidth <= PopularWidths.TABLET_MAX):
            isTablet = true;
            break;
        case (currentPageWidth >= PopularWidths.DESKTOP_S_MIN):
            isDesktop = true;
            break;
        default:
            isDesktop = true;
    }

    return {
        isMobileS,
        isMobileM,
        isMobileL,
        isTablet,
        isDesktop
    }
}